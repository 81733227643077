// pages/index.js
import Head from 'next/head'
import ApplicationShell from '../shared/components/ApplicationShell'
import ThreeColumnBlog from '../shared/components/ThreeColumnBlog'
import { getAllPosts } from '../shared/lib/api'
import Post from '../shared/types/post'

type Props = {
  allPosts: Post[]
}

export default function Home({ allPosts }: Props) {
  return (
    <div>
      <Head>
        <title>Rune Blog</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
      </Head>
      <ApplicationShell>
        <ThreeColumnBlog posts={allPosts} />
      </ApplicationShell>
    </div>
  )
}

export const getStaticProps = async () => {
  const allPosts = getAllPosts([
    'title',
    'date',
    'slug',
    'author',
    'previewImage',
    'previewVideo',
    'excerpt',
    'readingTime',
  ])

  return {
    props: { allPosts },
  }
}
