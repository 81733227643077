import Link from 'next/link'
import Post from '../types/post'
import Image from 'next/image'
import DateFormatter from './posts/DateFormatter'
import { BLOG_SITE_URL } from '../lib/constants'

type Props = {
  posts?: Post[]
}
export default function Example({ posts = [] }: Props) {
  return (
    <div className="relative bg-gray-50 px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pb-28">
      <div className="absolute inset-0">
        <div className="h-1/3 bg-white sm:h-2/3" />
      </div>
      <div className="relative mx-auto max-w-7xl">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">From the blog</h2>
          <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">
            Come see what we&apos;re up to. We are excited to add new content regularly. We write content about new
            technologies, new products, and new business opportunities.
          </p>
        </div>
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          {posts.map((post: any) => (
            <div key={post.title} className="relative flex max-w-full flex-col overflow-hidden rounded-lg shadow-lg">
              <div className="relative h-[192px] flex-shrink-0">
                {post.previewVideo?.length ? (
                  <video autoPlay muted loop height={192} className="max-h-[192px] w-full">
                    <source src={post.previewVideo} type="video/mp4" />
                  </video>
                ) : post?.previewImage?.length ? (
                  <Image className="h-48 w-full object-cover" layout="fill" src={post.previewImage} alt="" />
                ) : (
                  ''
                )}
              </div>
              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex-1">
                  {/* <p className="text-sm font-medium text-sky-600">
                    <a href={post.category.href} className="hover:underline">
                      {post.category.name}
                    </a>
                  </p> */}
                  <Link href={`${BLOG_SITE_URL}/posts/${post.slug}`}>
                    <span className="mt-2 block cursor-pointer">
                      <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                      <p className="mt-3 text-base text-gray-500">{post.description}</p>
                    </span>
                  </Link>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    {/* <a href={post.author.href}> */}
                    <span className="sr-only">{post.author.name}</span>
                    <Image className="h-10 w-10 rounded-full" src={post.author.picture} width={40} height={40} alt="" />
                    {/* </a> */}
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      {/* <a href={post.author.href} className="hover:underline"> */}
                      {post.author.name}
                      {/* </a> */}
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <DateFormatter dateString={post.date} />
                      <span aria-hidden="true">&middot;</span>
                      <span>{post.readingTime} read</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
